import http from '@/http-common';

export interface Project {
  id: string;
  name: string;
}

export class ProjectService {
  async getImportedProjects(): Promise<Project[]> {
    const result = await http.get('/projects/imported');
    return result.data;
  }

  async getProjectsToImport(): Promise<string[]> {
    const result = await http.get('/projects/to-import');
    return result.data;
  }
}

export const projectService = new ProjectService();
